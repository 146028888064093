<template>
  <div class="doc">
     <doc-pc class="doc-pc" v-if="screen > 434"></doc-pc>
     <doc-mobile class="doc-mobile" v-else-if="screen > 0 && screen <= 434"></doc-mobile>
  </div>
</template>
<script>

import docPc from "@/components/doc/doc-page.vue"
import docMobile from "@/components/doc/mobile-doc-page.vue"
export default {
  metaInfo: {
    title: "北京猎户星空科技有限公司",
    meta: [
      {
        name: "keywords",
        content:
          "猎户开发者文档中心，猎户开发者平台，OrionBase，机器人开发平台，机器人开发者平台，机器人开放平台，猎户开放平台，机器人开放平台"
      },
      {
        name: "description",
        content: "平台为开发者提供了简单高效的开发框架和功能强大的组件库，帮助开发者在机器人设备上开发满足业务场景需要的应用，大大降低了开发者的开发难度，提升开发效率"
      }
    ]
  },
  name: 'doc',
  data() {
    return {
      screen: 0
    };
  },
  components:{docPc,docMobile},
  created() {
    this.screen = window.innerWidth
    // console.log(this.screen);
  },
}
</script>
<style lang="less">
// .home.hidden {
//   display: none;
// }
.doc-pc {
  display: block;
}
.doc-mobile {
  display: none;
}
@media screen and (max-width: 434px) {
  .doc-pc {
    display: none;
  }
  .doc-mobile {
    display: block;
  }
}
</style>
